<template>
  <transition name="fade">
    <div class="disclaimer fade-component" v-if="init">
      <div class="disclaimer-container">
        <p class="welcome">{{ $t('message.welcome1') }} <br> {{ $t('message.welcome2') }}</p>
        <button @click="enter()" class="enter">{{ $t('message.enter') }}</button>
      </div>
    </div>
  </transition>
  <div id="openseadragon-viewer" style="width: 100%; height: 100vh;"></div>
  <transition name="fade">
    <div v-if="tutorial" class="tutorial fade-component">
      <div style="color: white">{{$t('message.tutorial1')}}<br>{{ $t('message.tutorial2') }}</div>
      <img class="cursor" id="cursor" src="../assets/icons/cursor.svg"/>
    </div>
  </transition>
</template>

<script>
import OpenSeadragon from 'openseadragon';

let viewer = null;

export default {
  name: 'OpenSeadragonViewer',
  mounted() {
    this.initializeViewer();
  },
  data() {
    return {
      init: true,
      tutorial: true,
    };
  },
  methods: {
    enter() {
      this.init = false
      this.$emit('enter')
    },
    initializeViewer() {
      viewer = OpenSeadragon({
        id: "openseadragon-viewer",
        prefixUrl: "https://cdnjs.cloudflare.com/ajax/libs/openseadragon/3.0.0/images/",
        tileSources: '/assets/hope_full.dzi',
        // {
        //   // Point to the DZI file within the assets directory
        //   // eslint-disable-next-line @typescript-eslint/no-var-requires
        //   url: 'https://verrochi92.github.io/axolotl/data/W255B_0.dzi' // require('@/assets/hope_full.dzi').default
        // },
        showNavigationControl: false,
        maxZoomPixelRatio: 2,
        minZoomLevel: 0.5,
        visibilityRatio: 1,
        constrainDuringPan: true,
        gestureSettingsTouch: {
          pinchToZoom: true,
          flickEnabled: true,
          flickMinSpeed: 10,
          flickMomentum: 0.3
        },
        crossOriginPolicy: "Anonymous",
      });
      viewer.addHandler('open', this.setInitialView);
      viewer.addHandler('canvas-drag', ()=>{this.tutorial = false});
    },
    setInitialView() {
      if (viewer) {
        const viewport = viewer.viewport;
        // Example: Setting initial position
        // const initialPosition = new OpenSeadragon.Point(1, 1); // X, Y coordinates in the image
        // const imagePoint = viewport.viewportToImageCoordinates(initialPosition);

        // console.log(initialPosition, imagePoint)

        // viewport.panTo(imagePoint);

        // Example: Setting initial zoom level
        const initialZoom = 6; // Zoom level, adjust as needed
        viewport.zoomTo(initialZoom);
      }
    }
  },
  beforeUnmount() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  }
}
</script>

<style scoped>
#openseadragon-viewer {
  background-color: #afeeee;
}
.disclaimer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 47px;
}
.tutorial {
  position: fixed;
  top: 25%;
  left: 60%;
  z-index: 2;
}
.cursor {
  position: relative;
  left: -30px;
  width: 30px;
}
.enter {
  background-color: #FFDACEDE;
  border: none;
  color: white;
  box-shadow: 0 0 30px 35px #FFDACEDE;
  border-radius: 60px;
  animation: pulse 4s infinite;
  cursor: pointer;
  padding: 10px;
}
.welcome {
  text-align: center;
  color: white;
}
.disclaimer {
  position: absolute;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: url('../assets/blured-bg.jpg');
  background-position: center;
  background-size: cover;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
@keyframes big-pulse {
  0% {
    transform: scale(1.0);
    box-shadow: 0 0 30px 35px #FFDACEDE;
  }
  50% {
    transform: scale(1.15);
    box-shadow: 0 0 30px 50px #FFDACEDE;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 30px 35px #FFDACEDE;
  }
}@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);

  }
  100% {
    transform: scale(1);
  }
}
.enter:hover {
  animation: big-pulse 3s infinite
}
</style>
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import ReleaseView from '../views/ReleaseView.vue'
import OSDView from '@/views/OSDView.vue'
import SendMessageView from '@/views/SendMessageView.vue'
import ReadMessagesView from '@/views/ReadMessagesView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/release',
    name: 'home',
    component: ReleaseView
  },
  {
    path: '/send',
    name:'send',
    component: SendMessageView
  },
  {
    path: '/read',
    name:'read',
    component: ReadMessagesView
  },
  {
    path: '/',
    name: 'fabric',
    component: OSDView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
  <div class="container">
     <div class="info">
        <img class="cover" src="../assets/cover.png" />
        <div class="textual">
          <div class="what">{{ $t('message.releaseNew') }}</div>
          <div class="desc">{{ $t('message.releaseDesc') }}</div>
          <div class="artist">{{ $t('message.releaseArtist') }}</div>
          <div class="links">
            <a class="link" v-for="link in Object.keys(links)" :href="links[link]" :key="link">
              <div class="link-text">{{ $t('message.listenOn') }} {{ link }}</div>
              <img class="link-icon" src="../assets/icons/link.svg" />
            </a>
          </div>
        </div>
     </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReleaseView',
  beforeMount() {
    this.$emit('enter')
    fetch("/assets/links.json")
      .then(response => response.json())
      .then(links => {
        this.links = links;
      })
      .catch(error => {
        console.error("Error fetching the JSON file:", error);
      });
  },
  data() {
    return {
      links: []
    }
  },
});
</script>
<style>
.container {
  background: url('../assets/blured-bg.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  gap: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.textual {
  color: white;
  display: flex;
  flex-direction: column;
}
.desc {
  margin-top: 30px;
  padding-right: 40px;
}
.artist {
  margin-top: 10px;
  text-align: right;
}
.cover {
  max-width: 440px;
  aspect-ratio: 1 / 1;
}
.links {
  padding-top: 150px;
  display: grid;
  column-gap: 7%;
  /* Будет создано 3 колонки */
  grid-template-columns: auto auto;
}
.link {
  display: flex;
  flex-flow: row;
  /* justify-content: space-between; */
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  text-decoration: none;
}
.link-text {
  display: inline-block;
  color: white;
}
.link-icon {
  /* margin-left: 10px; */
  width: 5px;
  margin-left: auto;
}
@media (max-width: 830px) {
  .container {
    padding-top: 80px;
  }
  .info {
    flex-direction: column;
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cover {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
  }
  .links {
    padding-top: 75px;
  }
}
@media (max-width: 420px) {
  .cover {
    height: auto;
    width: 100%;
  }
  .links {
    display: grid;
    /* Будет создано 3 колонки */
    grid-template-columns: 100%;
    padding-bottom: 100px;
  }
  .link-icon {
    margin-right: 5px;
  }
}

</style>

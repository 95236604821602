import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'ru',
  messages: {
    en: {
      message: {
        welcome1: 'Welcome to place of hope.',
        welcome2: 'For better experience, turn on sound.',
        enter: 'ENTER',
        exploreCover: 'Explore cover',
        leaveMsg: 'Leave your message',
        release: 'Listen to hope',
        tutorial1: 'Drag and move',
        tutorial2: 'to explore the cover',
        releaseNew: 'NEW EP ALBUM · 2024',
        releaseDesc: 'My voice is ruled by hope for a better world and honesty. I want to make hope viral. I wish for peace not only in the world, but also in everyone.',
        releaseArtist: '— Manizha',
        listenOn: 'Listen on',
        read: 'Read'
      }
    },
    ru: {
      message: {
        welcome1: 'Добро пожаловать в место, где живут наши надежды.',
        welcome2: 'Для более глубокого погружения включите звук.',
        enter: 'ВОЙТИ',
        exploreCover: 'Изучить обложку',
        leaveMsg: 'Оставить свое сообщение',
        release: 'Послушать надежду',
        tutorial1: 'Нажмите и перемещайтесь',
        tutorial2: 'по изображению',
        releaseNew: 'НОВЫЙ EP АЛЬБОМ · 2024',
        releaseDesc: 'Пусть наши надежды на лучший мир станут виральными. И помогут нам обрести мир не только на нашей планете, но и внутри кажого из нас.',
        releaseArtist: '— Манижа',
        listenOn: 'Слушать на',
        read: 'Читать'
      }
    }
  }
})

createApp(App).use(router).use(i18n).mount('#app')

<template>
  <nav class="navigation">
    <div class="nav-left">Make hope viral by Manizha</div>
    <ul v-if="menuUnlocked" class="nav-center">
      <li><a class="menu-item" :class="{selected: $route.href == '#/'}"  href="#/">{{ $t('message.exploreCover') }}</a></li>
      <li><a class="menu-item" :class="{selected: $route.href == '#/send'}"  href="#/send">{{ $t('message.leaveMsg') }}</a></li>
      <li><a class="menu-item" :class="{selected: $route.href == '#/release'}" href="#/release">{{ $t('message.release') }}</a></li>
      <li><a class="menu-item" :class="{selected: $route.href == '#/read'}" href="#/read">{{ $t('message.read') }}</a></li>
    </ul>
    <div v-if="menuUnlocked" class="nav-mobile">
      <img v-if="!menuOpened" class="" src="./assets/icons/menu-open.svg" @click="menuOpened = true" />
      <img v-if="menuOpened" class="" src="./assets/icons/menu-close.svg" @click="menuOpened = false" />
    </div>
    <div class="nav-right">
      <div class="lang-desktop">
        <button class="lang-btn" :class="{selected: $i18n.locale == 'en'}" @click="$i18n.locale = 'en'">EN</button>
        <button style="margin-left: 10px;" class="lang-btn" :class="{selected: $i18n.locale == 'ru'}" @click="$i18n.locale = 'ru'">РУ</button>
      </div>
      <img class="snd-btn" v-if="audioPaused" src="./assets/icons/mute.svg" @click="playAudio()" />
      <img class="snd-btn" v-else src="./assets/icons/unmute.svg" @click="pauseAudio()" />
    </div>
  </nav>
  <router-view @enter="unlockMenu"/>
  <!-- <footer style="z-index: 9;">
    © Manizha, 2024
  </footer> -->
  <div v-if="menuOpened" class="mobile-popover">
    <a @click="menuOpened=false" class="menu-item" href="#/" target="_blank" rel="noopener noreferrer">{{ $t('message.exploreCover') }}</a>
    <a @click="menuOpened=false" class="menu-item" href="#/send" target="_blank" rel="noopener noreferrer">{{ $t('message.leaveMsg') }}</a>
    <a @click="menuOpened=false" class="menu-item" href="#/release" target="_blank" rel="noopener noreferrer">{{ $t('message.release') }}</a>
    <a @click="menuOpened=false" class="menu-item" href="#/read" target="_blank" rel="noopener noreferrer">{{ $t('message.read') }}</a>
    
    <div class="lang-mobile">
      <button class="lang-btn" :class="{selected: $i18n.locale == 'en'}" @click="$i18n.locale = 'en'">EN</button>
      <button style="margin-left: 10px;" class="lang-btn" :class="{selected: $i18n.locale == 'ru'}" @click="$i18n.locale = 'ru'">РУ</button>
    </div>
  </div>
</template>
<script>
import sound from './assets/audio/candlelight.mp3'

const audio = new Audio(sound)

export default {
  data() {
    return {
      audioPaused: true,
      menuOpened: false,
      menuUnlocked: false,
    }
  },
  beforeMount() {
    console.log(this.$route.href)
  },
  methods: {
    unlockMenu() {
      this.menuUnlocked = true
    },
    playAudio() {
      audio.play();
      this.audioPaused = false
    },
    pauseAudio() {
      audio.pause();
      this.audioPaused = true
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Formular';
  src: url('./assets/fonts/Formular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
#app {
  font-family: Formular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0px;
}
.mobile-popover {
  position: absolute;
  top: 0px;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  padding-top: 72px;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 23px;
  color: white;
}
footer {
  color: white;
  bottom: 30px;
  position: fixed;
  text-align: center;
  width: 100%;
}
.nav-mobile {
  flex: 2;
  margin-left: 40px;
  display: none;
}

.navigation {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 30px;
  // margin-left: 40px;
  width: 100%;
  z-index: 9;
}
.nav-left {
  display: none;
  padding-left: 40px;
  flex: 1;
  text-align: left;
  color: white;
}

@media (max-width: 950px) {
  .nav-left {
    display: none;
  }
}

/* Center section styles */
.nav-center {
  flex: 2;
  justify-content: space-between;
  text-align: center;
  display: flex;
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 0px;
}

.lang-mobile {
  margin-top: 46px;
}

@media (max-width: 650px) {
  .nav-center {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
  .lang-desktop {
    display: none
  }
}

.menu-item {
  color: white;
  text-decoration: none;
  padding-bottom: 7px;
}

/* Right section styles */
.nav-right {
  padding-right: 40px;
  flex: 1;
  text-align: right;
  color: white;
  display: flex;
  justify-content: right;
}

.snd-btn {
  margin-left: 15px;
  width: 15px;
  cursor: pointer;
}

.lang-btn {
  font-family: Formular;
  font-size: 13px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  padding-bottom: 7px;
}

.selected {
  border-bottom: 2px solid white;
}
</style>

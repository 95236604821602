<template>
  <div class="send-container">
    <iframe
      width="500"
      height="410"
      src="https://manizha.os.fan/make-hope-viral"
      title="Openstage Fan Signup"
      frameborder="0"
      scrolling="no"
      style="max-width: 100%"
      allow="geolocation; microphone; camera; fullscreen; payment"
      sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
    />
  </div>
</template>
<script>
export default {
  beforeMount() {
    this.$emit('enter')
  }
}
</script>
<style scoped>
.send-container {
  background: url('../assets/blured-bg.jpg') no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
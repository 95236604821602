<template>
  <div class="container">
    <canvas class="desktop-list" id="canvas" width="100%" height="100%"></canvas>
    <div class="mobile-list">
      <div class="msg" v-for="m in messages" :key="m">
        {{ m.text }}
      </div>
    </div>
  </div>
</template>
<script>
import { Canvas, Group, Textbox, Image } from 'fabric';
import { defineComponent } from 'vue';

let canvas = null;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const textures = {
  1: require(`@/assets/textures/1.jpg`),
  2: require(`@/assets/textures/2.jpg`),
  3: require(`@/assets/textures/3.jpg`),
  4: require(`@/assets/textures/4.jpg`),
  5: require(`@/assets/textures/5.jpg`),
  6: require(`@/assets/textures/6.jpg`),
} 

export default defineComponent({
  mounted() {
    this.$emit('enter')
    fetch("/assets/messages.json")
      .then(response => response.json())
      .then(data => {
        this.messages = data;
        this.initCanvas();
        this.addRandomStickers();
        canvas.renderAll();
      })
      .catch(error => {
        console.error("Error fetching the JSON file:", error);
      });
  },
  data() {
    return {
      messages: [],
      canvas: null,
    }
  },
  methods: {
    // Initialize Fabric.js Canvas
    initCanvas() {
      canvas = new Canvas('canvas', {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    
    // Generate a random position within the canvas dimensions
    getRandomPosition(width, height) {
      const x = Math.floor(Math.random() * (window.innerWidth - width));
      let y = Math.floor(Math.random() * (window.innerHeight - height));
      if (y < 60) y = 60
      return { x, y };
    },

    // Add stickers with random positions and messages
    addRandomStickers() {
      this.messages.forEach((message) => {
        let { x, y } = this.getRandomPosition(200, 50); // Sticker size approx 200x50
        if (message.x != null) {
          x = message.x
        }
        if (message.y != null) {
          y = message.y
        }
        this.addSticker(message.text, x, y, message.rot, message.width);
      });
    },

    // Add a text message wrapped in a rectangle as a "sticker"
    async addSticker(message, left, top, rot, width) {
      // Create a rectangle for the background

      // Create the text element
      const text = new Textbox(message, {
        fontSize: 16,
        fill: '#000',
        originX: 'center',
        originY: 'center',
        fontFamily: 'Formular'
      });
      if (width) {
        text.set({ width });
      }
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const imagePath = textures[Math.floor(Math.random() * 6) + 1]

      const rect = await Image.fromURL(imagePath);
      rect.set({
        width: text.width + 140,
        height: text.height + 80,
        shadow: {
          color: 'rgba(0,0,0,0.25)', // Shadow color with opacity
          blur: 20, // Shadow blur
          offsetX: 0, // Horizontal shadow offset
          offsetY: 2  // Vertical shadow offset
        }
      });

      // Add the image to the canvas
      text.left = rect.width / 2
      text.top = rect.height / 2

      // Group rectangle and text together to create a sticker
      const sticker = new Group([rect, text], {
        left,
        top,
        selectable: true,
        lockScalingX: true,  // Disable horizontal scaling
        lockScalingY: true,  // Disable vertical scaling
        hasControls: false,
      });

      if (rot) {
        sticker.angle = rot
      } else {
        sticker.angle = Math.floor(Math.random() * 11) - 5;
      }

      // Add the sticker to the canvas
      canvas.add(sticker);
    },
  },
})
</script>
<style>
.container {
  background: url('../assets/blured-bg.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100vw;
}
.mobile-list {
  display: none;
  overflow-x: hidden;
}
.msg{
  /* background-size: cover; */
  /* background-position: center; */
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 70px;
  padding-right: 70px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 0px 0px rgb(0 0 0 / 25%);
}
.msg { 
  background: url('../assets/textures/1.jpg');
  transform:rotate(5deg); 
}
.msg:nth-child(2n) {
  background: url('../assets/textures/2.jpg');
  transform:rotate(-4deg);
}
.msg:nth-child(3n) {
  background: url('../assets/textures/3.jpg');
  transform:rotate(2deg);
}
.msg:nth-child(4n) {
  background: url('../assets/textures/4.jpg');
  transform:rotate(-3deg);
}
.msg:nth-child(5n) {
  background: url('../assets/textures/5.jpg');
  transform:rotate(-1deg);
}
.msg:nth-child(6n) {
  background: url('../assets/textures/6.jpg');
  transform:rotate(1deg);
}

@media (max-width: 430px) {
  .canvas-container {
    display: none;
  }
  .mobile-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
  }
}
</style>